import PageHeader from "../../components/PageHeader";
import Image from "../../components/Image";
import AboutPic from "../../images/pic.JPEG";
import Footer from "../../components/Footer";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const About = ({ brand }) => {
  const [bookRef, bookInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [natureRef, natureInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const [aboutDetailsRef, aboutDetailsInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const variants = {
    hidden: { y: "10vw", opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };
  return (
    <>
      <section className="about container">
        <PageHeader
          title="About Me"
          description="Learn more about me here."
          isLightMode="true"
        />

        <p className="brand">{brand}</p>
        <motion.div
          ref={aboutDetailsRef}
          className=""
          variants={variants}
          initial="hidden"
          animate={aboutDetailsInView ? "visible" : "hidden"}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div className="row container">
            <div className="col-12 col-xl-4">
              <Image
                src={AboutPic}
                alt="May Shin Lyan"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="col-12 col-xl-8 aboutDetails">
              <p>
                Hi, I'm May. I'm an entrepreneur, coder, and creative
                individual.
              </p>

              <p>
                I'm passionate about using technology and design-friendly
                aesthetics to bring positive change to the world. I care about
                people, the products I create, and their impact.
              </p>

              <p>
                I currently work full time at Google as a Google Cloud Solution
                Consultant. I have co-founded{" "}
                <a
                  href="https://www.bridgeburma.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  BridgeBurma
                </a>
                , an innovative educational platform, that provides study abroad
                and scholarship opportunities to Myanmar youth.
              </p>

              <p>
                During my free time, I like traveling around the world,
                exploring nature and learning about human. I like to stay active
                with doing yoga, pilates, gym, and running.
              </p>

              <p>
                I value authenticity, compassion, and personal growth. If you
                resonate with any of these or want to collaborate on cool
                projects, my inbox is always open. I'm based in San Mateo but I
                travel to Washington DC from time to time.
              </p>
            </div>
          </div>
        </motion.div>

        <div className="row about-section">
          <motion.div
            ref={bookRef}
            className=""
            variants={variants}
            initial="hidden"
            animate={bookInView ? "visible" : "hidden"}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <h1 className="mb-5">~ books ~</h1>
            <ul>
              <li>
                <motion.i className="fa-solid fa-book-open"></motion.i>
                &nbsp;Revenge of the Tipping Point by Malcolm Gladwell
              </li>
              <li>Courage to Dislike by Ichiro Kishimi, Fumitake Koga</li>
              <li>Rich Dad Poor Dad by Robert Kiyosaki</li>
              <li>7 Habits of Highly Effective People by Stephen Covey</li>
              <li>Atomic Habits by James Clear</li>
            </ul>
          </motion.div>
        </div>

        <div className="row about-section">
          <motion.div
            ref={natureRef}
            className=""
            variants={variants}
            initial="hidden"
            animate={natureInView ? "visible" : "hidden"}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <h1 className="mb-5">~ nature ~</h1>
            <ul>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Yellowstone
                National Park
              </li>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Yosemite
                National Park
              </li>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Grand Canyon
                National Park
              </li>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Zion
                National Park
              </li>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Joshua Tree
                National Park
              </li>
              <li>
                <motion.i className="fa-solid fa-check"></motion.i> Olympic
                National Park
              </li>
              <li>
                {" "}
                <motion.i className="far fa-circle"></motion.i> Rocky Mountain
                National Park
              </li>
              <li>
                <motion.i className="far fa-circle"></motion.i> Acadia National
                Park
              </li>
              <li>
                <motion.i className="far fa-circle"></motion.i> Great Smoky
                Mountains National Park
              </li>
              <li>
                <motion.i className="far fa-circle"></motion.i> Glacier National
                Park
              </li>
            </ul>
          </motion.div>
        </div>

        <div className="row about-section">
          <motion.div
            ref={natureRef}
            className="flex flex-row"
            variants={variants}
            initial="hidden"
            animate={natureInView ? "visible" : "hidden"}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <h1 className="mb-5">~ countries ~</h1>
            <div className="p4 flex-1">
              <ul>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇲🇲 Myanmar
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇹🇭
                  Thailand
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇸🇬
                  Singapore
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇨🇳 China
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇹🇷 Turkey
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇷🇴 Romania
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇩🇪 Germany
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇫🇷 France
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇮🇹 Italy
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇨🇭
                  Switzerland
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇨🇿 Czech
                  Republic
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇳🇱
                  Netherlands
                </li>
              </ul>
            </div>
            <div className="p4 flex-1">
              <ul>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇵🇹
                  Portugal
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇪🇬 Egypt
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇰🇪 Kenya
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇿🇦 South
                  Africa
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇦🇪 United
                  Arab Emirates
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇺🇸 United
                  States
                </li>
                <li>
                  <motion.i className="fa-solid fa-check"></motion.i> 🇨🇦 Canada
                </li>
                <li>
                  {" "}
                  <motion.i className="far fa-circle"></motion.i> 🇰🇷 South Korea
                </li>
                <li>
                  <motion.i className="far fa-circle"></motion.i> 🇲🇳 Mongolia
                </li>
                <li>
                  <motion.i className="far fa-circle"></motion.i> 🇲🇬 Madagascar
                </li>
                <li>
                  <motion.i className="far fa-circle"></motion.i> 🇯🇵 Japan
                </li>
                <li>
                  <motion.i className="far fa-circle"></motion.i> 🇳🇿 New Zealand
                </li>
                <li>
                  <motion.i className="far fa-circle"></motion.i> 🇦🇺 Australia
                </li>
              </ul>
            </div>
          </motion.div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
