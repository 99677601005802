import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// Components
import Header from "./components/Header";
import Landing from "./pages/landing/Landing";
import Portfolio from "./pages/portfolio/Portfolio";
import ProjectDetails from "./pages/portfolio/[projectTitle]/ProjectDetails";
import Blog from "./pages/blog/Blog";
import BlogDetails from "./pages/blog/[article]/BlogDetails";
import PageNotFound from "./pages/404/PageNotFound";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import ComingSoon from "./pages/comingSoon/comingSoon";

function App() {
  // Personal details for the user
  const personalDetails = {
    name: "May Shin Lyan",
    location: "Bay Area, CA",
    email: "mayshinlyan29@gmail.com",
    tagline: "Designer. Coder. Problem Solver.",
    brand: "love • peace • freedom • authenticity • integrity",
  };
  const location = useLocation();

  return (
    <>
      <Header />
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <Landing
              name={personalDetails.name}
              tagline={personalDetails.tagline}
            />
          }
        />
        <Route path="/creations" element={<Portfolio />} />
        <Route
          path="/about"
          element={<About brand={personalDetails.brand} />}
        />
        <Route path="/portfolio/:projectTitle" element={<ProjectDetails />} />
        <Route path="/blog/:articleTitle" element={<BlogDetails />} />
        <Route path="/blog" element={<Blog />} />

        <Route
          path="/contact"
          element={
            <Contact
              name={personalDetails.name}
              location={personalDetails.location}
              email={personalDetails.email}
            />
          }
        />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/page-not-found" />} />
      </Routes>
    </>
  );
}

export default App;
